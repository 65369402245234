import { useCallback } from 'react';
import { useUpdateUserMutation } from 'data-op/mutations/generated/updateUser';
import useRest from 'helpers/hooks/useRest';
import request from 'helpers/request';
import url from 'helpers/url';
import { useUser } from 'modules/context/Contexts/User';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { useRouter } from 'next/router';
import LoginTypes from 'types/enums/LoginTypes';
import { SocialAuth } from 'types/interfaces/SocialAuth';
import sanitizeURL from 'utils/sanitizeUrl';

import Cookie from '@lyearn/core/utils/cookie';

import { setLoginCookies } from '../utils/setLoginCookies';

const socialLoginMap: Partial<Record<LoginTypes, string>> = {
  [LoginTypes.Google]: 'googleAuthenticator',
  [LoginTypes.Facebook]: 'facebookAuthenticator',
  [LoginTypes.LinkdIn]: 'linkedinAuthenticator',
  [LoginTypes.Outlook]: 'outlookAuthenticator',
};

export default function useAuthentication() {
  const user = useUser();
  const router = useRouter();
  const { site } = useInstanceConfig();

  const [{ data, fetching, error }, updateUser] = useUpdateUserMutation();

  const [fetchStatus, , , execute] = useRest(
    '',
    {
      method: 'POST',
      body: JSON.stringify({ emailId: '', password: '' }),
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    },
    {
      sendAuthHeader: true,
    },
  );

  const handleLookupEmail = async (data: any) => {
    const response = await request('/api/auth/accountLookup', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    });
    const responseData = await response.json();
    if (responseData.success) {
      return null;
    } else {
      return responseData;
    }
  };

  const handleForgotPassword = async (data: any) => {
    const response = await request('/api/auth/password/forget', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    });
    const responseData = await response.json();
    if (responseData.success) {
      return null;
    } else {
      return responseData;
    }
  };

  const handleSetPassword = async (data: any) => {
    const response = await request('/api/auth/password/set', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    });
    const responseData = await response.json();
    if (responseData.success) {
      return null;
    } else {
      return responseData;
    }
  };

  const handleSignUp = async (data: any, handleClose: Function, redirectPath: string) => {
    const response = await request('/api/auth/register', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    });
    const responseData = await response.json();

    if (responseData.success) {
      setLoginCookies(responseData?.loginToken);
      /* Changed, not trying to refresh user before a window redirection */
      /* const { refreshUser } = user;
      await refreshUser(); */
      handleClose();
      if (redirectPath) {
        (window as any).sanitizeURL = sanitizeURL;
        const url = sanitizeURL(redirectPath);
        window.location.href = url;
      }
    }
  };

  const handleVerifyUser = useCallback(
    async (data: any) => {
      const response = await (execute as any)({
        requestBody: JSON.stringify(data),
        url: '/api/auth/verifyEmailId',
      });

      if (response && response.success) {
        const { refreshUser, id } = user;
        if (id) {
          refreshUser();
        }
        return true;
      } else {
        return false;
      }
    },
    [execute, user],
  );

  const handleSocialAuth = (props: SocialAuth) => {
    const { type, socialType = LoginTypes.Google, redirectPath, handleClose } = props;
    const authType = type === 'login' ? 'login' : 'signUp';
    const redirectUrl =
      encodeURIComponent(window.location.origin + redirectPath) || window.location.origin;
    if (handleClose) {
      handleClose();
    }
    const accessToken = Cookie.get('accessToken');
    const subDomain = site.subdomain;
    const host = window.location.host;
    window.location.href = `${url}/auth/login/${
      socialLoginMap[socialType]
    }?authType=${authType}&redirectUrl=${redirectUrl}&accessToken=${accessToken}&storeName=${
      site.storeName
    }&host=${host}&subDomain=${subDomain || 'sprinklruniversity'}`;
  };

  const handleUpdateUser = async (updatedUser: any, redirectPath?: string) => {
    const { id, refreshUser } = user;
    if (id) {
      const response = await updateUser({
        userDetails: {
          userId: id,
          name: updatedUser.name,
          emailId: updatedUser.email,
          meta: updatedUser.meta,
        },
      });

      if (response.error) {
        console.error('Update user mutation failed.');
        return;
      } else {
        await refreshUser();
        if (redirectPath) {
          router.replace(sanitizeURL(decodeURIComponent(redirectPath)));
        }
      }
    }
  };

  return {
    fetchStatus,
    handleSignUp,
    handleSocialAuth,
    handleUpdateUser,
    handleLookupEmail,
    handleVerifyUser,
    handleForgotPassword,
    handleSetPassword,
  };
}
