import { DocumentNode, Kind } from 'graphql/index';
import { nanoid } from 'nanoid';
import { ExchangeInput, Operation } from 'urql';
import {
  filter,
  fromPromise,
  fromValue,
  map,
  merge,
  mergeMap,
  pipe,
  share,
  Source,
  takeUntil,
} from 'wonka';

import user from '../currentUser';
import refetchTokens from '../fetchTokens';
import parseJwt from '../parseJWT';
import { getOperationName } from './utils';

const addTokenToOperation = (operation: Operation, token: string) => {
  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  const operationName = getOperationName(operation.query);

  // @ts-ignore
  let url = fetchOptions.headers?.['x-request-id']
    ? operation.context.url
    : operation.context.url + `?op=${operationName}`;

  return {
    ...operation,
    context: {
      ...operation.context,
      url,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          'x-request-id': nanoid(),
          'x-operation-name': operationName,
          accessToken: token,
        },
      },
    },
  };
};

const isTokenExpired = () => {
  const accessToken = user.accessToken();
  let isExpired = true;
  try {
    if (accessToken) {
      const expiresAt = parseJwt(accessToken as string).exp;
      isExpired = expiresAt < Date.now() / 1000;
    }
  } catch (e) {
    console.error('Error parsing userId from token', e);
  }
  return isExpired;
};

const getToken = () => user.accessToken() as string;

export const authExchange = ({ forward }: ExchangeInput) => {
  let refreshTokenPromise: Promise<any> | null = null;

  return (ops$: Source<Operation>) => {
    const sharedOps$ = pipe(ops$, share);

    const withToken$ = pipe(
      sharedOps$,
      // Filter by non-teardowns
      filter((operation) => operation.kind !== 'teardown'),
      mergeMap((operation) => {
        // check whether the token is expired
        const isExpired = refreshTokenPromise || isTokenExpired();

        // If it's not expired then just add it to the operation immediately
        if (!isExpired) {
          return fromValue(addTokenToOperation(operation, getToken()));
        }

        // If it's expired and we aren't refreshing it yet, start refreshing it
        if (isExpired && !refreshTokenPromise) {
          refreshTokenPromise = refetchTokens(); // we share the promise
        }

        const { key } = operation;
        // Listen for cancellation events for this operation
        const teardown$ = pipe(
          sharedOps$,
          filter((op) => op.kind === 'teardown' && op.key === key),
        );

        return pipe(
          fromPromise(refreshTokenPromise!),
          // Don't bother to forward the operation, if it has been cancelled
          // while we were refreshing
          takeUntil(teardown$),
          map(() => {
            refreshTokenPromise = null; // reset the promise variable
            return addTokenToOperation(operation, getToken());
          }),
        );
      }),
    );

    // We don't need to do anything for teardown operations
    const withoutToken$ = pipe(
      sharedOps$,
      filter((operation) => operation.kind === 'teardown'),
    );

    // @ts-ignore
    return pipe(merge([withToken$, withoutToken$]), forward);
  };
};
